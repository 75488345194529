import logo from '~assets/ticsocial_icon.png';
import Internationalization from '~layouts/Internationalization/Internationalization';
import './ticsocialWatermark.scss';

const TicsocialWatermark = () => {
  return (
    <div className="ticsocial_watermark">
      <div className="d-flex align-items-center gap-4">
        <Internationalization />
        <img src={logo} alt="logo ticsocial" />
      </div>
    </div>
  );
};

export default TicsocialWatermark;
